import * as React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout/Layout'
import { 
  container,
  content,
  header,
  h1,
} from "../styles/project.module.css"

export default function project({ data }) {
  const project = data.markdownRemark
  return (
    <Layout>
      <header className={header}>
        <div className={container}>
        <h1 className={h1}>{project.frontmatter.title}</h1>
        </div>
      </header>
      <div className={container}>
        <div className={content} dangerouslySetInnerHTML={{ __html: project.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        thumbnail {
          absolutePath
          relativePath
        }
      }
    }
  }
`